<template>
  <div id="todo">
    <div id="lado_A">
      <h1>LAMINAS</h1>
      <v-card
        class="mx-auto"
        max-width="100%"
        outlined
        v-for="(item, i) in imagens"
        :key="i"
        :v-if="teste(item.grupo)"
        id="espacoVcard"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-0">{{ item.grupo }}</div>
            <v-list-item-subtitle class="mb-4">
              {{ item.tecido }}
            </v-list-item-subtitle>
            <v-list-item-title class="text-h5 mb-1">
              {{ item.nome }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.resumo }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-card-actions>
            <v-col cols="auto">
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab v-bind="attrs" v-on="on" id="sizeBT">
                    <v-avatar size="90">
                      <img :src="item.src" alt="John" />
                    </v-avatar>
                  </v-btn>
                </template>

                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="#00636b" dark>
                      {{ item.nome }} - {{ item.resumo }}
                    </v-toolbar>
                    <v-carousel
                      :show-arrows="true"
                      hide-delimiters
                      height="100%"
                    >
                      <v-carousel-item v-for="(img, i) in item.imgs" :key="i">
                        <v-sheet height="100%" tile>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                            <div id="configZoon">
                              <div id="sombra">
                                <v-avatar size="400" id="avatarZoonG">
                                  <img :src="img" alt="John" />
                                </v-avatar>
                                <v-avatar size="250" id="avatarZoonP">
                                  <img :src="img" alt="John" />
                                </v-avatar>
                              </div>
                              <br />
                            </div>
                            <div id="textoZoon">
                              Zoon Digital: {{ i === 0 ? 1 : i * 2 }}x
                            </div>
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                    <div id="conteudoZoom">
                      <p id="parag_01">
                        {{ item.info }}
                      </p>
                    </div>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false">Sair</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
                <!-- ------------- -->
              </v-dialog>
            </v-col>
          </v-card-actions>
        </v-list-item>
      </v-card>
    </div>

    <v-divider vertical></v-divider>

    <div id="lado_B"></div>
  </div>
</template>

<script>
export default {
  name: "Laminas",
  data: () => ({
    imagens: [
      {
        nome: "Bexiga",
        tecido: "Bexiga",
        grupo: "Tecido Epitelial",
        resumo: "Lente Objetiva 40x",
        info: "A bexiga é constituída por um epitélio de transição. Quando a bexiga está vazia, muitas células têm superfície em abóbada e quando ela está cheia as células tornam-se achatadas. ",
        src: "/imgs/bexiga_a.jpg",
        imgs: [
          "/imgs/bexiga_a.jpg",
          "/imgs/bexiga_2x_a.jpg",
          "/imgs/bexiga_4x_a.jpg",
        ],
      },
      {
        nome: "Cartilagem Fibrosa",
        tecido: "Disco intervertegral",
        grupo: "Cartilagem",
        resumo: "Lente Objetiva 40x",
        src: "/imgs/cartilagem_fibrosa_a.jpg",
        imgs: [
          "/imgs/cartilagem_fibrosa_a.jpg",
          "/imgs/cartilagem_fibrosa_2x_a.jpg",
          "/imgs/cartilagem_fibrosa_4x_a.jpg",
        ],
        info: "Pode ser encontrada também na sínfise púbica, tendões e ligamentos na inserção de músculos, onde exercem função de sustentação e resistência à torção, tensão e pressão mecânica. Apresenta caráter predominantemente acidófilo por conter quantidade elevada de fibras colágenas tipo I, e, por isso, se cora de rosa. Estas fibras de colágeno formam feixes de organização irregular entre os condrócitos ou um arranjo paralelo ao longo do condrócitos em fileiras. Diferentemente das demais cartilagens não apresenta pericôndrio, sendo que sua nutrição é feita pelo líquido sinovial.",
      },
      {
        nome: "Cartilagem Fibrosa",
        tecido: "Traquéia",
        grupo: "Cartilagem",
        resumo: "Lente Objetiva 40x",
        src: "/imgs/cartilagem_hialina_a.jpg",
        imgs: [
          "/imgs/cartilagem_hialina_a.jpg",
          "/imgs/cartilagem_hialina_2x_a.jpg",
          "/imgs/cartilagem_hialina_4x_a.jpg",
        ],
        info: "A cartilagem hialina pode ser encontrada na parede das fossas nasais, traqueia e brônquios, além da extremidade ventral das costelas e da cobertura de superfícies articulares dos ossos longos. Histologicamente é envolta por pericôndrio (tecido conjuntivo fonte de novos condrócitos, responsável pela nutrição, oxigenação e eliminação de refugos metabólicos) e apresenta condroblastos (células com formato fusiforme e núcleo achatado, responsável por produção de matriz cartilaginosa) na periferia. Os Condrócitos são as células predominantes do tecido, sendo que na periferia possuem forma ovóide e na profundida forma mais globosa, podendo formar grupos isógenos que se originam de um único condrócito. Sua matriz pode ser dividida em duas, sendo elas: Matriz territorial - constituída por colágeno (ou colágeno e elastina), proteoglicanos, ácido hialurônico e glicoproteínas, localizada em torno de condrócitos isolados ou em torno de grupos isógenos, corresponde a uma matriz mais densa - e matriz interterritorial, menos concentrada localizada e localizada mais afastada das células.",
      },
      {
        nome: "Derme",
        tecido: "Derme profunda da pele",
        grupo: "Tecido Conjuntivo",
        resumo: "Lente Objetiva 40x",
        src: "/imgs/derme_a.jpg",
        imgs: [
          "/imgs/derme_a.jpg",
          "/imgs/derme_2x_a.jpg",
          "/imgs/derme_4x_a.jpg",
        ],
        info: "Tecido conjuntivo denso não modelado rico em fibras colágenas dispostas em direções não orientadas. Esse tecido suporta trações em diferentes direções. Observe que as fibras são mais numerosas que as células.",
      },
      {
        nome: "Epididímo",
        tecido: "Epididímo",
        grupo: "Tecido Epitelial",
        resumo: "Lente Objetiva 40x",
        src: "/imgs/epididimo_a.jpg",
        imgs: [
          "/imgs/epididimo_a.jpg",
          "/imgs/epididimo_2x_a.jpg",
          "/imgs/epididimo_4x_a.jpg",
          "/imgs/epididimo2_a.jpg",
          "/imgs/epididimo2_2x_a.jpg",
          "/imgs/epididimo2_4x_a.jpg",
        ],
        info: "Tecido conjuntivo denso não modelado rico em fibras colágenas dispostas em direções não orientadas. Esse tecido suporta trações em diferentes direções. Observe que as fibras são mais numerosas que as células.",
      },
      {
        nome: "Epididímo",
        tecido: "Epididímo",
        grupo: "Tecido Epitelial",
        resumo: "Lente Objetiva ??x",
        src: "/imgs/epididimo2_a.jpg",
        imgs: [
          "/imgs/epididimo2_a.jpg",
          "/imgs/epididimo2_2x_a.jpg",
          "/imgs/epididimo2_4x_a.jpg",
        ],
        info: "Tecido conjuntivo denso não modelado rico em fibras colágenas dispostas em direções não orientadas. Esse tecido suporta trações em diferentes direções. Observe que as fibras são mais numerosas que as células.",
      },
    ],
  }),
  methods: {
    teste(item) {
      console.log(item === "Tecido Epitelial" ? true : false);
      return item === "Tecido Epitelial" ? true : false;
    },
  },
};
</script>

<style scoped>
#configZoon {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

#sombra {
  background-color: rgb(173, 173, 173);
  box-shadow: 0 0 1em rgb(173, 173, 173);
  border-radius: 100%;
}

#sizeBT {
  width: 94px;
  height: 94px;
}

#avatarZoonG {
  display: flex;
}
#avatarZoonP {
  display: none;
}

#textoZoon {
  width: 100%;
  height: 61px;
  text-align: center;
  line-height: 48px;
  font-family: "roboto";
  font-size: 20px;
  font-weight: 500;
  color: gray;
}

#conteudoZoom {
  width: 100%;
  padding: 25px;
}

#espacoVcard {
  margin-top: 10px;
}

h1 {
  font-size: 50px;
}

#todo {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
}

#lado_A {
  width: 50%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 25px;
  color: #00636b;
  /* background-color: aliceblue; */
}
#lado_B {
  width: 50%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 25px;
  color: #00636b;
  /* background-color: aquamarine; */
}

#parag_01 {
  width: 100%;
  text-align: justify;
  text-justify: distribute;
}
#parag_02 {
  width: 100%;
  font-size: 8pt;
  text-align: justify;
  text-justify: distribute;
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 25px;
  }
  #todo {
    flex-direction: column;
    background-size: 100%;
  }
  #lado_A {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #lado_B {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  #parag_01 {
    width: 100%;
  }
  #parag_02 {
    width: 100%;
  }
  #avatarZoonG {
    display: none;
  }
  #avatarZoonP {
    display: flex;
  }
}
</style>
