import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Histologia from "../views/Histologia.vue";
import Patologia from "../views/Patologia.vue";
import Microscopia from "../views/Microscopia.vue";
import Laminas from "../views/Laminas.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/histologia",
    name: "Histologia",
    component: Histologia,
  },
  {
    path: "/patologia",
    name: "Patologia",
    component: Patologia,
  },
  {
    path: "/microscopia",
    name: "Microscopia",
    component: Microscopia,
  },
  {
    path: "/laminas",
    name: "Laminas",
    component: Laminas,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
